import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  margin: 5rem auto 4rem auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  & .subtitle, & .title {
    text-align: center;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  & .title {
    text-transform: uppercase;
  }

  & .caption {
    text-align: center;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  & .poster {
    display: flex;
    margin: 4rem auto 0 auto;
    width: inherit;
    max-width: 60rem;

    & > img {
      display: flex;
      width: 100%;
      height: auto;
      object-fit: contain;
      align-self: flex-start;
    }
  }

  .grid-column:nth-child(1),
  .grid-column:nth-last-child(1) {
    text-align: center;
    justify-content: center;
  }

  .grid-column:nth-last-child(1) {
    & > div {
      margin-bottom: 3rem;

      .large {
        margin-bottom: 1.5rem;

        & > p {
          font-size: 2em;
        }
      }
    }
  }

  .mobile.location {
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;

    & > div {
      justify-content: center;
    }
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    margin: 0 auto 7rem auto;

    & .caption {
      max-width: 50%;
    }

    & .poster {
      margin: 0 auto;
      max-width: unset;
    }

    .grid-column:nth-child(1),
    .grid-column:nth-last-child(1) {
      padding-top: 4rem;
    }

    .grid-column:nth-last-child(1) {
      & > div {
        margin-bottom: 4rem;
        .large {
          margin-bottom: 2rem;
        }
      }
    }
  }

  ${up('desktop')} {
    margin: 0 auto 12rem auto;

    & .subtitle, & .title {
      margin-bottom: 4rem;
      max-width: 70%;
    }

    & .caption {
      max-width: 40%;
    }

    & .poster {
      margin: 0 auto;
    }

    .grid-column:nth-child(1),
    .grid-column:nth-last-child(1) {
      padding-top: 6rem;
    }

    .grid-column:nth-last-child(1) {
      h3 {
        max-width: 70%;
        margin: 0 auto;
      }

      & > div {
        margin-bottom: 5rem;
        .large {
          margin-bottom: 2.5rem;
        }
      }
    }
  }


  ${up('largeDesktop')} {
    margin: 0 auto 25rem auto;

    & .subtitle, & .title {
      max-width: 50%;
      margin-bottom: 8rem;
    }

    & .caption {
      max-width: 25%;
    }

    & .poster {
      margin: 0 auto;
    }

    .grid-column:nth-child(1),
    .grid-column:nth-last-child(1) {
      padding-top: 25rem;
    }

    .grid-column:nth-last-child(1) {
      h3 {
        max-width: 50%;
      }

      & > div {
        margin-bottom: 9rem;

        .large {
          margin-bottom: 4.5rem;
        }
      }
    }
  }
`

export default Wrapper
