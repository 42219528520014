import React from 'react'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import Breadcrumbs from 'src/sections/Breadcrumbs'
import Layout from 'src/layout'
import SEO from 'src/components/SEO'
import ExpositionLocation from 'src/sections/ExpositionLocation'
import AnnouncementHeader from 'src/sections/AnnouncementHeader'
import createDateComponent from 'src/utils/createDateComponent'
import removeHTMLTags from 'src/utils/removeHTMLTags'

const createAnnouncement = (announcement: any) => {
  const exposition = announcement.acf.exposition
  const title = removeHTMLTags(exposition.title)
  const subtitle = removeHTMLTags(exposition.acf.authors)
  const caption = removeHTMLTags(exposition.acf.about)
  const poster = announcement?.better_featured_image?.source_url || exposition.acf.poster.source_url

  const slug = announcement.slug

  const location = {
    location: announcement.acf.city,
    slug: announcement.slug,
    date: {
      date_start: createDateComponent(announcement.acf.date.start),
      date_end: createDateComponent(announcement.acf.date.end)
    }
  }

  return {
    exposition: {
      slug: exposition.slug,
      title,
      subtitle,
      caption,
      poster
    },
    slug,
    location,
    place: {
      name: announcement.acf.name,
      address: announcement.acf.address,
      schedule: announcement.acf.schedule
    }
  }
}

const AnnouncementPage = (props: any) => {
  const announcement = createAnnouncement(props.pageContext.announcement)
  const exposition = announcement.exposition

  return (
    <Layout>
      <SEO url={props.location.href} />
      <ProgressBar />
      <main>
        <div className="desktop">
          <Navbar />
        </div>
        <Breadcrumbs
          announcement
          url={exposition.slug}
          title={exposition.title}
        />
        <ExpositionLocation location={announcement.location} />
        <AnnouncementHeader
          pathname={props.location.pathname}
          location={announcement.location}
          title={exposition.title}
          subtitle={exposition.subtitle}
          caption={exposition.caption}
          poster={exposition.poster}
          place={{
            name: announcement.place.name,
            address: announcement.place.address,
            schedule: announcement.place.schedule
          }}
        />
      </main>
    </Layout>
  )
}

export default AnnouncementPage
