import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H3 from 'src/components/Typography/H3'
import H5 from 'src/components/Typography/H5'
import Caption from 'src/components/Typography/Caption'
import LocationGroup from 'src/components/LocationGroup'
import Wrapper from './style'

interface Props {
  pathname: string
  title: string
  subtitle: string
  caption: string
  poster: string
  location: any
  place: {
    name: string
    address: string
    schedule: {
      uptime: string,
      weekDays: string
    }[]
  }
}

const AnnouncementHeader: React.FC<Props> = props => {
  const { pathname, title, subtitle, caption, poster, location, place } = props

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4}>
            <div className="subtitle">
              <H5 italic dangerouslySetInnerHTML={{ __html: subtitle }} />
            </div>
            <div className="title">
              <H2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className="caption">
              <Caption dangerouslySetInnerHTML={{ __html: caption }} />
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="poster">
              <img src={poster} alt={title} />
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="mobile location">
              <LocationGroup pathname={pathname} extend display item={location} />
            </div>
            <div>
              <H5 italic>{place.address}</H5>
            </div>
            <div>
              <H3>{place.name}</H3>
            </div>
            {place.schedule.map((timing, index) => (
              <div className="timing-wrapper" key={index}>
                <div className="large">
                  <Caption>{timing.uptime}</Caption>
                </div>
                <div>
                  <Caption>{timing.weekDays}</Caption>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default AnnouncementHeader
